import { Check, FlagOutlined, FlagRounded } from "@mui/icons-material";
import { twJoin, twMerge } from "tailwind-merge";
import ParchaLoadingScreen from "../V2_Home_Page/ParchaLoadingScreen";
import { Feedback } from "@/types";

type FeedbackButtonsProps = {
  isLoadingFeedback: boolean;
  isFail: boolean;
  flaggedSelected: boolean;
  setFlaggedSelected: (value: boolean) => void;
  feedback?: Feedback;
  handleSubmitFeedback: (isValid: boolean, rowId: string, comment: string) => Promise<void>;
  rowId: string;
  setFeedbackComment: (value: string) => void;
  setShowFeedbackComment: (value: boolean) => void;
};

const FeedbackButtons = ({
  isLoadingFeedback,
  isFail,
  flaggedSelected,
  setFlaggedSelected,
  feedback,
  handleSubmitFeedback,
  rowId,
  setFeedbackComment,
  setShowFeedbackComment,
}: FeedbackButtonsProps) => {
  return (
    <div className="flex items-center gap-x-1">
      {isLoadingFeedback ? (
        <ParchaLoadingScreen size="small" message="" />
      ) : (
        <>
          <button
            className={twJoin(
              "p-2 rounded-lg border",
              flaggedSelected || feedback?.score === false
                ? "bg-red-600 border-red-500 text-white"
                : isFail
                  ? "hover:bg-red-200 border-red-200 text-red-700"
                  : "hover:bg-slate-200 border-slate-200 text-slate-500",
            )}
            onClick={(e) => {
              e.preventDefault();
              e.stopPropagation();
              setFlaggedSelected(!flaggedSelected);
            }}
          >
            {flaggedSelected || feedback?.score === false ? (
              <FlagRounded sx={{ fontSize: "1rem" }} />
            ) : (
              <FlagOutlined sx={{ fontSize: "1rem" }} />
            )}
          </button>
          <button
            className={twMerge(
              "p-2 rounded-lg border",
              feedback?.score === true
                ? "bg-green-600 border-green-600 text-white"
                : isFail
                  ? "hover:bg-red-200 border-red-200 text-red-700"
                  : "border-slate-200 hover:bg-slate-200 text-slate-500",
              flaggedSelected && feedback?.score === true ? "bg-opacity-50 text-white border-green-200" : "",
            )}
            onClick={(e) => {
              e.preventDefault();
              e.stopPropagation();
              setFlaggedSelected(false);
              setFeedbackComment("");
              setShowFeedbackComment(false);
              handleSubmitFeedback(true, rowId, "");
            }}
          >
            <Check sx={{ fontSize: "1rem" }} />
          </button>
        </>
      )}
    </div>
  );
};

export default FeedbackButtons;
