import { useState } from "react";
import { twJoin, twMerge } from "tailwind-merge";
import MiniStrengthIndicator from "./MiniStrengthIndicator";
import HitMatchIndicator from "./HitMatchIndicator";
import PerpetratorTable from "./PerpetratorTable";
import FeedbackButtons from "./FeedbackButtons";
import FeedbackStatus from "./FeedbackStatus";
import FeedbackInput from "./FeedbackInput";
import { Feedback, Hit } from "@/types";

type RiskCardV3Props = {
  hit: Hit;
  input: unknown;
  handleSubmitFeedback: (isValid: boolean, rowId: string, comment: string) => Promise<void>;
  feedback?: Feedback;
  expandByDefault: boolean;
  isLoadingFeedback: boolean;
  type: "pep" | "adverse_media";
  renderContent: () => React.ReactNode;
};

const RiskCardV3 = ({
  hit,
  input,
  handleSubmitFeedback,
  feedback,
  expandByDefault,
  isLoadingFeedback,
  type,
  renderContent,
}: RiskCardV3Props) => {
  const [showEventInfo, setShowEventInfo] = useState(expandByDefault);
  const [showFeedbackComment, setShowFeedbackComment] = useState(false);
  const [flaggedSelected, setFlaggedSelected] = useState(false);
  const [feedbackComment, setFeedbackComment] = useState("");

  const isFail = hit.profile_review.match_rating.match === "strong_match";
  const rowId = type === "pep" ? hit.reference_id : hit.id;

  const onSubmitFeedbackClick = async () => {
    await handleSubmitFeedback(false, rowId ?? "", feedbackComment);
  };

  return (
    <div className={twJoin("flex flex-col overflow-hidden", showEventInfo ? "rounded-t-lg" : "rounded-lg")}>
      {/* Card Header */}
      <div
        className={twMerge(
          "flex items-center border border-transparent justify-between py-3 pr-3 pl-5 flex-wrap lg:flex-nowrap gap-2 group",
          isFail ? "bg-red-100" : "bg-slate-50",
          showEventInfo || showFeedbackComment || flaggedSelected ? "rounded-t-lg border-b-0" : "rounded-lg",
          !showEventInfo ? (isFail ? "hover:border-red-200" : "hover:border-slate-300") : "",
        )}
        role="button"
        onClick={() => setShowEventInfo(!showEventInfo)}
      >
        <div className="flex flex-col w-full">
          <div className="font-semibold">{hit.full_name}</div>
          <div className="inline-flex items-center text-xs text-slate-500">
            {hit.age_as_of_today && <span>{hit.age_as_of_today} years old</span>}
            {hit.associated_countries && hit.associated_countries.length > 0 && (
              <span className="truncate">
                {hit.age_as_of_today && ", "}
                {hit.associated_countries
                  .map((country: any) =>
                    country !== null && typeof country === "object" && "country_name" in country
                      ? country.country_name
                      : country,
                  )
                  .join(", ")}
              </span>
            )}
          </div>
        </div>

        {/* Feedback Controls */}
        <div className="flex flex-col w-full items-end gap-2">
          <div className="flex items-center gap-x-2 w-fit text-xs flex-1 shrink-0">
            {hit.profile_review.match_rating.match !== "no_match" && (
              <MiniStrengthIndicator matchRating={hit.profile_review.match_rating.match} />
            )}
            <HitMatchIndicator
              text={
                hit.profile_review.match_rating.match === "no_match"
                  ? "Not a Match"
                  : type === "pep"
                    ? "PEP"
                    : "Adverse Media"
              }
              riskLevel={
                hit.profile_review.match_rating.match === "strong_match"
                  ? "high"
                  : hit.profile_review.match_rating.match === "partial_match" ||
                      hit.profile_review.match_rating.match === "weak_match"
                    ? "low"
                    : "no_match"
              }
            />
            {/* Feedback Buttons */}
            <FeedbackButtons
              isLoadingFeedback={isLoadingFeedback}
              isFail={isFail}
              flaggedSelected={flaggedSelected}
              setFlaggedSelected={setFlaggedSelected}
              feedback={feedback}
              handleSubmitFeedback={handleSubmitFeedback}
              rowId={rowId ?? ""}
              setFeedbackComment={setFeedbackComment}
              setShowFeedbackComment={setShowFeedbackComment}
            />
          </div>

          {/* Feedback Status */}
          {!isLoadingFeedback && feedback && feedback.score === false && (
            <FeedbackStatus
              feedback={feedback}
              showEventInfo={showEventInfo}
              showFeedbackComment={showFeedbackComment}
              setShowFeedbackComment={setShowFeedbackComment}
              flaggedSelected={flaggedSelected}
              setFlaggedSelected={setFlaggedSelected}
              setFeedbackComment={setFeedbackComment}
              isFail={isFail}
            />
          )}
        </div>
      </div>

      {/* Feedback Input */}
      {(flaggedSelected || showFeedbackComment) && (
        <FeedbackInput
          isFail={isFail}
          showEventInfo={showEventInfo}
          feedbackComment={feedbackComment}
          setFeedbackComment={setFeedbackComment}
          feedback={feedback}
          showFeedbackComment={showFeedbackComment}
          setShowFeedbackComment={setShowFeedbackComment}
          flaggedSelected={flaggedSelected}
          setFlaggedSelected={setFlaggedSelected}
          onSubmitFeedbackClick={onSubmitFeedbackClick}
        />
      )}

      {/* Content Section */}
      {showEventInfo && (
        <div className="flex flex-col gap-y-2 border-x border-b rounded-b-lg border-slate-200">
          <PerpetratorTable mediaHit={hit} input={input} />
          <div className="px-5 pt-2.5">{renderContent()}</div>
        </div>
      )}
    </div>
  );
};

export default RiskCardV3;
