const HitMatchIndicator = ({ text, riskLevel }: { text: string; riskLevel: "high" | "low" | "no_match" }) => {
  const outerDivClassnames = {
    high: "border border-red-200 p-1",
    low: "border border-slate-200 p-1",
    no_match: "border-slate-200",
  };

  const riskLevelClassnames = {
    high: "bg-red-600",
    low: "bg-slate-600",
    no_match: "bg-slate-400",
  };

  return (
    <div className={`mx-2 rounded-full ${outerDivClassnames[riskLevel]} text-white text-xs font-semibold`}>
      <div className={`px-2 py-[1.92px] rounded-full truncate h-fit ${riskLevelClassnames[riskLevel]}`}>{text}</div>
    </div>
  );
};

export default HitMatchIndicator;
