import React, { Dispatch, Fragment, useEffect, useMemo, useState } from "react";
import { MagnifyingGlassIcon } from "@heroicons/react/20/solid";
import { Combobox, Dialog, Transition } from "@headlessui/react";
import clsx from "clsx";
import { formatDistanceToNowStrict } from "date-fns";
import { AgentRun, Endpoint } from "@/types";
import { ArrowRightAlt } from "@mui/icons-material";
import { useParchaApi } from "@/hooks/useParchaApi";
import debounce from "lodash/debounce";
import ParchaLoadingScreen from "../V2_Home_Page/ParchaLoadingScreen";

export default function CaseSearch({
  endpoint,
  show,
  setShow,
}: {
  endpoint: Endpoint;
  show: boolean;
  setShow: Dispatch<React.SetStateAction<boolean>>;
}) {
  const [query, setQuery] = useState("");
  const [filteredJobs, setFilteredJobs] = useState<(AgentRun & { applicant_id: string })[]>([]);
  const [isSearchingJobs, setIsSearchingJobs] = useState(false);
  const parchaApi = useParchaApi();

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setQuery(e.target.value);
  };

  const debouncedResults = useMemo(() => {
    return debounce(handleChange, 200);
  }, []);

  const fetchJobs = async () => {
    await parchaApi
      .searchJobs(endpoint.endpointUrl, endpoint.agentKey, query)
      .then((res) => {
        setFilteredJobs(res);
      })
      .finally(() => {
        setIsSearchingJobs(false);
      });
  };

  useEffect(() => {
    return () => {
      debouncedResults.cancel();
    };
  });

  useEffect(() => {
    if (query.length >= 2) {
      setIsSearchingJobs(true);
      setFilteredJobs([]);
      fetchJobs();
    } else {
      setFilteredJobs([]);
    }
  }, [query]);

  return (
    <Transition.Root show={show} as={Fragment} afterLeave={() => setQuery("")} appear>
      <Dialog as="div" className="relative z-20" onClose={setShow}>
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-white bg-opacity-95 transition-opacity" />
        </Transition.Child>

        <div className="fixed inset-y-[20%] inset-x-0 z-20 w-screen overflow-y-auto p-4 sm:p-6 md:p-20">
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0 scale-95"
            enterTo="opacity-100 scale-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100 scale-100"
            leaveTo="opacity-0 scale-95"
          >
            <Dialog.Panel className="mx-auto max-w-xl transform divide-y divide-gray-100 overflow-hidden border border-solid border-slate-300 rounded-xl bg-white shadow-2xl ring-1 ring-black ring-opacity-5 transition-all">
              <Combobox
                onChange={(job: AgentRun) =>
                  (window.location.href = `/jobs/${job.agent_id}/${job.id?.replace(/-/g, "")}`)
                }
              >
                <div className="relative">
                  <Combobox.Input
                    className="h-12 w-full border-0 bg-transparent pl-4 text-slate-900 placeholder:text-gray-400 focus:ring-0 sm:text-sm"
                    placeholder="Enter a Business Name of Case ID..."
                    onChange={debouncedResults}
                    autoFocus
                  />
                  <MagnifyingGlassIcon
                    className="pointer-events-none absolute right-4 top-[35%] h-5 w-5 text-gray-400"
                    aria-hidden="true"
                  />
                </div>

                {filteredJobs?.length > 0 && (
                  <Combobox.Options
                    static
                    className="max-h-72 scroll-py-2 overflow-y-auto py-2 text-sm text-gray-800 divide divide-y divide-y-slate-400"
                  >
                    {filteredJobs.slice(0, 50).map((job) => (
                      <Combobox.Option
                        key={job.id}
                        value={job}
                        className={({ active }) =>
                          clsx(
                            "cursor-default select-none px-4 py-2 flex justify-between items-center w-full",
                            active && "bg-brand-purple text-white",
                          )
                        }
                      >
                        {({ active }) => (
                          <div className="w-full flex gap-x-2 justify-between items-center">
                            <div className="flex flex-col grow-0 max-w-2/3">
                              <span className="line-clamp-1 font-semibold">
                                {job.input_payload?.self_attested_data?.name ||
                                  job.input_payload?.self_attested_data?.business_name ||
                                  job.input_payload?.self_attested_data?.registered_business_name ||
                                  `${job.input_payload?.self_attested_data?.first_name || ""}${
                                    job.input_payload?.self_attested_data?.middle_name
                                      ? ` ${job.input_payload.self_attested_data.middle_name}`
                                      : ""
                                  }${
                                    job.input_payload?.self_attested_data?.last_name
                                      ? ` ${job.input_payload.self_attested_data.last_name}`
                                      : ""
                                  }`}
                              </span>
                              <div className="flex flex-col gap-x-2 truncate text-xs">
                                {job.applicant_id && (
                                  <span className="overflow-hidden truncate">{job.applicant_id}</span>
                                )}
                                {job?.started_at && (
                                  <span className="grow-0 font-extralight">
                                    {formatDistanceToNowStrict(new Date(`${job.started_at}Z`), {
                                      addSuffix: true,
                                    })}
                                  </span>
                                )}
                              </div>
                            </div>
                            {active && <ArrowRightAlt />}
                          </div>
                        )}
                      </Combobox.Option>
                    ))}
                  </Combobox.Options>
                )}
                {isSearchingJobs && (
                  <div className="h-[6.125rem]">
                    <ParchaLoadingScreen size="small" message="Searching..." />
                  </div>
                )}
                {!isSearchingJobs && query.length > 2 && filteredJobs?.length === 0 && (
                  <p className="font-medium text-lg bg-slate-50 text-slate-900 w-full h-[6.125rem] inline-flex justify-center items-center text-sm text-gray-500">
                    No results found.
                  </p>
                )}
              </Combobox>
            </Dialog.Panel>
          </Transition.Child>
        </div>
      </Dialog>
    </Transition.Root>
  );
}
