// @ts-nocheck
import { useEffect, useRef, useState } from "react";
import { useSearchParams } from "react-router-dom";
import { useParchaApi } from "@parcha/hooks/useParchaApi";
import _ from "lodash";
import {
  ErrorRounded,
  RefreshRounded,
  EmailRounded,
  HourglassTopRounded,
  LocationOnRounded,
  CalendarTodayRounded,
  CancelRounded,
  Check,
} from "@mui/icons-material";
import CheckCircleIcon from "@mui/icons-material/CheckCircleRounded";
import WarningIcon from "@mui/icons-material/WarningRounded";
import SmartTabs from "./TailwindComponents/SmartTabs";
import CheckContainer from "./V2_Home_Page/CheckContainer";
import Alert from "./TailwindComponents/Alert";
import StatusIndicator from "./TailwindComponents/StatusIndicator";
import { differenceInYears, format, parseISO } from "date-fns";

const Embbed = () => {
  const parchaApi = useParchaApi();
  const [searchParams] = useSearchParams();
  const endpointUrl = import.meta.env.VITE_API_ENDPOINT_URL;
  const agentKey = import.meta.env.VITE_AGENT_KEY;
  const caseId = searchParams.get("case_id");
  const apiKey = searchParams.get("api_key");
  const [error, setError] = useState<string | null>(null);
  const [checks, setChecks] = useState<string[] | null>(null);
  const [displayedCheck, setDisplayedCheck] = useState<any | null>(null);
  const [showTabular, setShowTabular] = useState<boolean>(true);
  const [jobMetadata, setJobMetadata] = useState<any | null>(null);

  const tabOrder = ["kyc.adverse_media_screening_check_v2", "kyc.pep_screening_check_v2"];

  const onContactSupportClickHandler = () => {
    let mailtoLink;
    if (error) {
      const subject = `Error report in ${agentKey}`;
      const body = `The following error occured while loading the case data.\n\n${error}`;
      mailtoLink = `mailto:support@parcha.ai?subject=${encodeURIComponent(subject)}&body=${encodeURIComponent(
        body || "",
      )}`;
    } else {
      const errorMessage = displayedCheck?.status_messages?.find((message) => message.event === "error")?.content
        .error_display;
      const subject = `Error report in ${agentKey} for job ${jobMetadata?.job?.id}`;
      const body = `There was an error job with ID ${jobMetadata?.job?.id}.\n\n${errorMessage ? errorMessage : ""}`;
      mailtoLink = `mailto:support@parcha.ai?subject=${encodeURIComponent(subject)}&body=${encodeURIComponent(
        body || "",
      )}`;
    }
    window.open(mailtoLink, "_blank");
  };

  const intervalRef = useRef(null);

  useEffect(() => {
    let intervalId;

    const fetchJobData = () => {
      parchaApi.getJobMetadataByCaseId(endpointUrl, caseId, agentKey, apiKey).then((data) => {
        setJobMetadata(data);
        const checks = data.kyc
          ? Object.values(data.kyc)[0]?.check_results?.filter((check: any) => check.command_id !== "finish_plan")
          : [];
        setChecks(checks);
      });
    };

    if (caseId && endpointUrl && apiKey) {
      fetchJobData();
      intervalRef.current = setInterval(fetchJobData, 5000);
    }

    return () => {
      if (intervalRef.current) {
        clearInterval(intervalRef.current);
      }
    };
  }, [caseId, endpointUrl, apiKey]);

  useEffect(() => {
    if (checks && checks.length > 0 && !displayedCheck) {
      setDisplayedCheck(checks.find((check: { command_id: string }) => check.command_id === tabOrder[0]) || checks[0]);
    } else if (checks && checks.length > 0 && displayedCheck !== null) {
      setDisplayedCheck(
        checks?.find(
          (check: { command_instance_id: string }) => check.command_instance_id === displayedCheck?.command_instance_id,
        ),
      );
    }
  }, [checks]);

  useEffect(() => {
    if (jobMetadata?.job?.status === "complete" || jobMetadata?.job?.status === "error") {
      clearInterval(intervalRef.current);
    }
  }, [jobMetadata?.job?.status]);

  const getRowsCount = (check: any) => {
    if (check.command_id === "kyc.adverse_media_screening_check_v2") {
      return check.check_result?.verified_adverse_media_hits?.length ?? 0;
    }
    if (check.command_id === "kyc.pep_screening_check_v2") {
      return check.check_result?.verified_pep_hits?.length ?? 0;
    }
    return 1;
  };

  const getBadgeLevel = (check: any) => {
    const listToCheck =
      check.command_id === "kyc.adverse_media_screening_check_v2"
        ? check.check_result?.verified_adverse_media_hits
        : check.check_result?.verified_pep_hits;

    return listToCheck?.some((hit: any) => hit.profile_review.match_rating.match === "strong_match") ? "high" : "low";
  };

  const tabs =
    checks && checks.length > 0
      ? [
          ...tabOrder
            .map((commandId) => {
              const check = checks.find((c) => c.command_id === commandId);
              if (!check) return null;
              return {
                id: check?.command_id,
                label: check?.command_name.replace(" Check", "") || check?.command_id,
                rowsCount: getRowsCount(check),
                badgeLevel: getBadgeLevel(check),
                icon:
                  check.recommendation === "Approve" ? (
                    <CheckCircleIcon fontSize="small" className="text-green-600" />
                  ) : check.recommendation === "Review" || check.recommendation === "Deny" ? (
                    <WarningIcon fontSize="small" className="text-yellow-600" />
                  ) : null,
              };
            })
            .filter(Boolean),
          ...checks
            .filter((check) => !tabOrder.includes(check.command_id))
            .map((check) => ({
              id: check.command_id,
              label: check.command_name.replace(" Check", "") || check.command_id,
              rowsCount: getRowsCount(check),
              badgeLevel: getBadgeLevel(check),
              icon:
                check.recommendation === "Approve" ? (
                  <CheckCircleIcon fontSize="small" className="text-green-600" />
                ) : check.recommendation === "Review" || check.recommendation === "Deny" ? (
                  <WarningIcon fontSize="small" className="text-yellow-600" />
                ) : null,
            })),
        ]
      : [];

  const onTabSelectHandler = (tabId) => {
    setDisplayedCheck(checks?.find((check) => check?.command_id === tabId));
  };

  const selfAttestedData = jobMetadata?.job?.input_payload?.self_attested_data;

  const anyCheckIsNotPassed = checks?.some((check) => check.passed === false);

  return (
    <div className="flex flex-col gap-y-4 h-full overflow-auto">
      {selfAttestedData && (
        <div className="flex justify-between items-baseline p-5 bg-slate-50 text-xs text-slate-900 border-b border-slate-200">
          <div className="flex flex-col gap-y-2">
            <span className="text-sm font-semibold">
              {selfAttestedData?.first_name} {selfAttestedData?.last_name}
            </span>
            <div className="flex items-center gap-x-5 text-slate-700">
              {selfAttestedData?.date_of_birth && (
                <div className="flex items-center gap-x-1">
                  <CalendarTodayRounded sx={{ fontSize: "1rem" }} className="text-slate-500" />
                  <span>
                    {differenceInYears(new Date(), parseISO(selfAttestedData.date_of_birth))},{" "}
                    {format(parseISO(selfAttestedData.date_of_birth), "MMMM do yyyy")}
                  </span>
                </div>
              )}
              {selfAttestedData?.country_of_residence && (
                <div className="flex items-center gap-x-1">
                  <LocationOnRounded sx={{ fontSize: "1rem" }} className="text-slate-500" />
                  <span>{selfAttestedData?.country_of_residence}</span>
                </div>
              )}
            </div>
          </div>
          <div className="flex flex-col gap-y-2 items-end">
            {jobMetadata?.job.status === "complete" ? (
              anyCheckIsNotPassed ? (
                <div className="flex items-center gap-x-1">
                  <p className="px-3 py-2 bg-red-100 text-red-700 inline-flex items-center gap-x-1 text-sm font-medium rounded-md">
                    <CancelRounded sx={{ fontSize: "1rem" }} />
                    High Risk
                  </p>
                </div>
              ) : (
                <div className="flex items-center gap-x-1">
                  <p className="px-3 py-2 bg-green-100 text-green-700 inline-flex items-center gap-x-1 text-sm font-medium rounded-md">
                    <Check sx={{ fontSize: "1rem" }} />
                    Low Risk
                  </p>
                </div>
              )
            ) : null}
            {jobMetadata?.job?.status === "queued" && (
              <div className="flex items-center gap-x-1">
                <StatusIndicator status={jobMetadata?.job?.status} />
                <span className="text-xs text-slate-500 capitalize">{jobMetadata?.job?.status}</span>
              </div>
            )}
          </div>
        </div>
      )}
      {jobMetadata?.job?.status === "queued" ? (
        <div className="flex flex-col justify-center items-center w-full h-full text-brand-purple text-sm gap-y-2">
          <HourglassTopRounded sx={{ fontSize: "2rem" }} className="text-slate-500" />
          <div className="text-center">
            <p className="font-bold">This job is queued up</p>
            <p>It will start executing once the current in progress jobs are completed.</p>
          </div>
        </div>
      ) : jobMetadata?.job?.status === "error" || error ? (
        <div className="flex items-center h-full mx-auto w-3/4">
          <div className="flex flex-col justify-center items-center w-full py-10 px-5 lg:p-10 text-sm gap-y-2 bg-slate-50 rounded-[1.25rem]">
            <ErrorRounded sx={{ fontSize: "2rem" }} className="text-red-600" />
            <div className="text-center">
              <p className="text-xs lg:text-sm font-semibold lg:font-bold text-slate-700">An error has occurred</p>
              {error && <p className="text-slate-500 text-xs">There was an issue loading the case data.</p>}
              {jobMetadata?.job && (
                <>
                  <p className="text-slate-500 text-xs">There was an issue while loading information for</p>
                  <p className="text-slate-500 font-bold text-xs">
                    {jobMetadata?.job?.input_payload?.self_attested_data?.name ||
                      jobMetadata?.job?.input_payload?.self_attested_data?.business_name ||
                      jobMetadata?.job?.input_payload?.self_attested_data?.registered_business_name ||
                      `${jobMetadata?.job?.input_payload?.self_attested_data?.first_name || ""}${
                        jobMetadata?.job?.input_payload?.self_attested_data?.middle_name
                          ? ` ${jobMetadata?.job?.input_payload.self_attested_data.middle_name}`
                          : ""
                      }${
                        jobMetadata?.job?.input_payload?.self_attested_data?.last_name
                          ? ` ${jobMetadata?.job?.input_payload.self_attested_data.last_name}`
                          : ""
                      }`}
                  </p>
                </>
              )}
              <button
                type="button"
                className="mt-2 text-sm relative inline-flex justify-center items-center rounded-md bg-white border border-solid border-slate-300 p-2 h-4 text-slate-900 focus:z-10 "
                onClick={() => window.location.reload()}
              >
                <RefreshRounded sx={{ fontSize: "1rem" }} className="mr-1 h-4 w-4" aria-hidden="true" />
                <span className="line-clamp-1 text-xs">Refresh This Page</span>
              </button>
              <p className="mt-4 text-xs text-slate-500">
                If the problem persists please contact support and we will look into the issue as soon as possible.
              </p>
              <button
                type="button"
                className="mt-2 text-sm relative inline-flex justify-center items-center rounded-md bg-white border border-solid border-slate-300 p-2 h-4 text-slate-900 focus:z-10 "
                onClick={onContactSupportClickHandler}
              >
                <EmailRounded sx={{ fontSize: "1rem" }} className="mr-1 h-4 w-4" aria-hidden="true" />
                <span className="line-clamp-1 text-xs">Contact Support</span>
              </button>
            </div>
          </div>
        </div>
      ) : null}

      <div className="flex flex-col gap-y-2">
        {tabs && tabs.length > 0 && (
          <SmartTabs tabs={tabs} selectedTab={displayedCheck?.command_id} onSelect={onTabSelectHandler} />
        )}

        {displayedCheck && displayedCheck.recommendation && (
          <Alert
            level={
              displayedCheck.recommendation === "Approve"
                ? "success"
                : displayedCheck.recommendation === "Review"
                  ? "partial"
                  : displayedCheck.recommendation === "Deny"
                    ? "failure"
                    : "normal"
            }
            message={
              <div className="flex flex-col gap-y-2">
                <div className="flex items-center gap-x-2">
                  {displayedCheck.recommendation && displayedCheck.answer && (
                    <span>
                      <span className="capitalize font-semibold">
                        {displayedCheck.recommendation === "Approve"
                          ? "Low Risk"
                          : displayedCheck.recommendation === "Review"
                            ? "Medium Risk"
                            : displayedCheck.recommendation === "Deny"
                              ? "High Risk"
                              : displayedCheck.recommendation}
                        :
                      </span>{" "}
                      {displayedCheck.answer}
                    </span>
                  )}
                </div>
              </div>
            }
          />
        )}

        {displayedCheck && (
          <div key={displayedCheck.commandInstanceId} className="flex flex-col gap-y-5">
            <CheckContainer
              title={displayedCheck.command_name}
              agentRun={jobMetadata?.job}
              apiKey={apiKey}
              endpointUrl={endpointUrl}
              commandResult={displayedCheck}
              key={displayedCheck.commandInstanceId}
              messages={displayedCheck.status_messages || []}
              auditLogMessages={
                displayedCheck.status_messages?.filter((message: any) =>
                  ["api_results", "command_results", "thought"].includes(message.event),
                ) || []
              }
              compact={true}
              showTabular={showTabular}
            />
          </div>
        )}
      </div>
    </div>
  );
};

export default Embbed;
