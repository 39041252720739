import { twJoin } from "tailwind-merge";

type FeedbackInputProps = {
  isFail: boolean;
  showEventInfo: boolean;
  feedbackComment: string;
  setFeedbackComment: (value: string) => void;
  feedback: any;
  showFeedbackComment: boolean;
  setShowFeedbackComment: (value: boolean) => void;
  flaggedSelected: boolean;
  setFlaggedSelected: (value: boolean) => void;
  onSubmitFeedbackClick: () => Promise<void>;
};

const FeedbackInput = ({
  isFail,
  showEventInfo,
  feedbackComment,
  setFeedbackComment,
  feedback,
  showFeedbackComment,
  setShowFeedbackComment,
  setFlaggedSelected,
  onSubmitFeedbackClick,
}: FeedbackInputProps) => {
  return (
    <div
      className={twJoin(
        "flex flex-col pr-3 pl-5 gap-y-2 pt-4",
        isFail ? "bg-red-100" : "bg-slate-50",
        showEventInfo ? "" : "rounded-b-lg",
      )}
    >
      <input
        className="w-full p-2 border border-slate-200 rounded-lg text-xs placeholder:text-slate-400"
        type="text"
        value={feedbackComment}
        onChange={(e) => setFeedbackComment(e.target.value)}
        placeholder="Please provide feedback on how we can improve..."
      />
      <div className="flex items-center gap-x-1 justify-end w-full text-xs pb-4">
        <button
          type="button"
          onClick={() => {
            if (showFeedbackComment) {
              setShowFeedbackComment(false);
            } else {
              setFlaggedSelected(false);
            }
            setFeedbackComment("");
          }}
          className="border border-slate-200 rounded-lg px-3 py-2 text-slate-500"
        >
          Cancel
        </button>
        <button
          disabled={feedback ? feedback.comment === feedbackComment : feedbackComment.length === 0}
          type="button"
          onClick={onSubmitFeedbackClick}
          className="border border-slate-200 rounded-lg px-3 py-2 bg-brand-purple text-white disabled:bg-slate-200 disabled:text-slate-500"
        >
          {feedback?.comment ? "Update Feedback" : "Send Feedback"}
        </button>
      </div>
    </div>
  );
};

export default FeedbackInput;
